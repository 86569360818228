<template>
  <div class="ShiftSetting_I">
    <div class="TablesList">
      <div class="TablesADD">
        <div class="NavLiReset" @click="BtnSearch(false)"><i class="el-icon-refresh"></i>刷新</div>
        <div class="NavLiQuery" @click="showAdd()" v-if="TextMoMo('attendance:group:add')"><i class="el-icon-plus"></i>添加班次</div>
        <div class="TableIpt"> 
          <input @keyup.enter.native="BtnSearch()" type="text" v-model="search.keyword" placeholder="请输入班次名称">
          <div class="seso" @click="BtnSearch()"><i class="el-icon-search"></i></div>
        </div>
      </div>
      <el-table
        :data="tableData"
        class="table"
        ref="multipleTable"
        header-cell-class-name="table-header">
        <el-table-column prop="id" label="ID" width="70" align="center"></el-table-column>
        <el-table-column align="center" prop="title" label="班次名称"></el-table-column>
        <el-table-column align="center" prop="leader.realname" label="班次责人"></el-table-column>
        <el-table-column align="center" prop="section" label="考勤时间"></el-table-column>
        <el-table-column label="操作" align="center" width="200">
          <template #default="scope">
              <div class="el_butto" >
                <el-button
                type="text"
                @click="HandlEdit(scope.row)"
                v-if="TextMoMo('attendance:group:edit')"
                >编辑</el-button>
              </div>
            <div class="el_butto rere" >
                <el-button
                type="text"
                class="red"
                v-if="TextMoMo('attendance:group:del')"
                @click="HandlDel(scope.row)"
                >删除</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          background
          @current-change="handleCurrentChange"
          :current-page="page.currentPage"
          :page-size="page.pageSize"
          layout="total, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
    </div>

    <div class="show" v-if="addPanel || editPanel">
      <div class="rightPanel-background"/>
      <div class="EditNew">
        <div class="EditNewTop">
          <div>{{titleMath}}</div>
          <div @click="Close()"><i class="el-icon-close"></i></div>
        </div>
        <div class="EditNewFormBtns">
          <div class="EditNewFormItemName">班次名称：</div>
          <div class="EditNewFormItemIpt">
            <el-input v-model="Row.title" placeholder="请输入班次名称"></el-input>
          </div>
        </div>
        <div class="EditNewFormBtns">
          <div class="EditNewFormItemName">考勤负责人：</div>
          <div class="EditNewFormItemIpt">
            <el-select v-model="Row.leader_id" placeholder="请选择考勤负责人">
              <el-option v-for="item in StaFFList" :key="item.id" :label="item.realname" :value="item.id"></el-option>
            </el-select>
          </div>
        </div>
        <div class="EditNewFormBtns">
          <div class="EditNewFormItemName">打卡时段：</div>
            <div>
              <el-radio v-model="Row.num" :label="1" @change="Radio">1天1次上下班</el-radio>
              <el-radio v-model="Row.num" :label="2" @change="Radio">1天2次上下班</el-radio>
              <el-radio v-model="Row.num" :label="3" @change="Radio">1天3次上下班</el-radio>
            </div>
        </div>
        <div class="EditNewFormBtns" style="width: 97.5%;" v-for="(item, index) in SectionList" :key="item">
          <div class="EditNewFormItemName">第{{index + 1}}次</div>
            <div>
              <el-time-select placeholder="上班时间" v-model="item.start" style="width: 45%;"></el-time-select>
              <span class="timeSpan" :span="2">至</span>
              <el-time-select placeholder="下班时间" v-model="item.end" style="width: 45%;"></el-time-select>
            </div>
        </div>
        <div class="EditNewFormBtns">
          <div class="EditNewFormItemName">是否休息： <el-checkbox v-model="Row.is_rest" :true-label="1" :false-label="0"></el-checkbox></div>
        </div>
        <div class="EditNewFormBtns" v-if="Row.is_rest == 1">
          <div class="EditNewFormItemName">休息时间</div>
            <div>
              <el-time-select placeholder="休息时间" v-model="Row.rest_starttime" style="width: 45%;"></el-time-select>
              <span class="timeSpan" :span="2">至</span>
              <el-time-select placeholder="上班时间" v-model="Row.rest_endtime" style="width: 45%;"></el-time-select>
            </div>
        </div>
        <div class="EditNewFormBtns">
          <div class="EditNewFormItemName">更多设置</div>
          <div class="check">
            <el-checkbox v-model="reissue_on" :true-label="1" :false-label="0">晚到、提前几分钟不记为异常</el-checkbox>
          </div>
          <div v-if="reissue_on == 1">
            <div>上班最多可晚到：<el-input type="number" v-model="Row.late_time" placeholder="10" style="width:20%"></el-input>&nbsp;&nbsp;晚到10分钟内不算迟到</div>
            <div>下班最多可提前：<el-input type="number" v-model="Row.early_time" placeholder="10" style="width:20%;"></el-input>&nbsp;&nbsp;提前5分钟内不算早退</div>
          </div>
          <div class="check">
            <el-checkbox v-model="reissue_on1" :true-label="1" :false-label="0">晚到、提前几分钟不记为缺卡</el-checkbox>
          </div>
          <div v-if="reissue_on1 == 1">
            <div>上班最多可晚到：<el-input type="number" v-model="Row.miss_am_time" placeholder="10" style="width:20%"></el-input>&nbsp;&nbsp;晚到30分钟内不算缺卡半日</div>
            <div>下班最多可提前：<el-input type="number" v-model="Row.miss_pm_time" placeholder="10" style="width:20%;"></el-input>&nbsp;&nbsp;提前30分钟内不算缺卡半日</div>
          </div>
          <div class="check">
            <el-checkbox v-model="reissue_on2" :true-label="1" :false-label="0">允许提前、延后打卡</el-checkbox>
          </div>
          <div v-if="reissue_on2 == 1">
            <div>打卡最多可提前：<el-input type="number" v-model="Row.advance_time" placeholder="10" style="width:20%"></el-input>&nbsp;&nbsp;可以提前100分钟打卡</div>
            <div>打卡最多可延后：<el-input type="number" v-model="Row.daily_time" placeholder="10" style="width:20%;"></el-input>&nbsp;&nbsp;可以延后30分钟打卡</div>
          </div>
        </div>
        <div class="EditNewFormBtns">
          <div class="FormBtns">
            <div v-if="addPanel == true" class="FormBtnsQuery" @click="BtnAdd()">创建</div>
            <div v-if="editPanel == true" class="FormBtnsQuery" @click="BtnEdit()">保存</div>
            <div class="FormBtnsClose" @click="Close()">取消</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import  TextMoth  from "../../../components/utils.vue";
export default {
  data(){
    return{
      tableData:[],
      SectionList:[], //班次时间
      StaFFList:[],
      total:'',
      titleMath:'',
      reissue_on:'',
      reissue_on1:'',
      reissue_on2:'',
      editId:'',
      addPanel: false,
      editPanel: false,
      // 分页
      page: {
        currentPage: 1,
        pageSize: 10,
      }, 
      //搜索参数
      search: {
        keyword: "",
      },
      Row:{
        title:'',
        leader_id:'',
        num:1,
        is_rest:'',
        rest_starttime:'',
        rest_endtime:'',
        late_time:'',
        early_time:'',
        miss_am_time:'',
        miss_pm_time:'',
        advance_time:'',
        daily_time:'',
        sections:[],
      }
    }
  },
  mounted(){
    var that = this
    that.loadTable(); 
  },
  methods:{
    // 获取列表数据
    loadTable() {
      var that = this
      axios({
        method: 'post',
        url: '/admin/attendance/attenclass/index',
        data: {
         page: that.page.currentPage,
         limit: that.page.pageSize,
         filter: that.search,
        },
      }).then(function (res) {
        that.tableData = res.data.data.rows
        that.total = res.data.data.total
        that.$forceUpdate()
      })
    }, 
    //全部员工列表
    quanbu(){
      var that = this
      axios.get('/admin/ajax/staffList',{                   // 全部员工列表
      }).then(function (res) {
        if (res.data.code == 0) {
          alert('请求失败');  
        } else{ 
          that.StaFFList = res.data.data
        }
      })
    } , 
    // 创建打开
    showAdd() {
      var that = this
      that.addPanel = true;
      that.quanbu()
      that.titleMath = '新增班次'
      that.Row = {
        title:'',
        leader_id:'',
        num: '',
        is_rest: 0,
        rest_starttime:'',
        rest_endtime:'',
        late_time:'',
        early_time:'',
        miss_am_time:'',
        miss_pm_time:'',
        advance_time:'',
        daily_time:'',
        sections:[],
      }; 
      that.reissue_on = 1,
      that.reissue_on1 = 1,
      that.reissue_on2 = 1
    },
    //新增
    BtnAdd(){
      var that = this 
      axios({
        method: 'post',
        url: '/admin/attendance/attenclass/add',
        data: {
          row : {
            title: that.Row.title,
            leader_id: that.Row.leader_id,
            num:that.Row.num,
            is_rest: that.Row.is_rest,
            rest_starttime: that.Row.rest_starttime,
            rest_endtime: that.Row.rest_endtime,
            late_time: that.Row.late_time,
            early_time: that.Row.early_time,
            miss_am_time: that.Row.miss_am_time,
            miss_pm_time: that.Row.miss_pm_time,
            advance_time: that.Row.advance_time,
            daily_time: that.Row.daily_time,
            sections: that.SectionList,
          }
        },
      }).then(function (res) {
        console.log(res.data);
        if (res.data.code == 0) {
          that.$message.warning(res.data.msg);
        } else {
          that.$message.success(res.data.msg);
          that.addPanel = false
          that.loadTable();
        }
      })
    },
    //编辑
    HandlEdit(data){
      var that = this
      that.editPanel = true
      that.titleMath = '编辑班次'
      that.quanbu()
      that.editId = data.id
      axios.get('/admin/attendance/attenclass/read', {   // 详情
        params: {
          id: data.id
        }
      }).then(function (res) {
        console.log(res.data.data);
        if (res.data.code == 0) {
          alert('请求失败');
        } else{
            that.Row.title = res.data.data.row.title.toString()
            that.Row.leader_id = parseInt(res.data.data.row.leader_id)
            that.Row.num = res.data.data.row.num 
            that.Row.is_rest = res.data.data.row.is_rest 
            that.Row.rest_starttime = res.data.data.row.rest_starttime 
            that.Row.rest_endtime = res.data.data.row.rest_endtime 
            that.Row.late_time = res.data.data.row.late_time
            that.Row.early_time = res.data.data.row.early_time 
            that.Row.miss_am_time = res.data.data.row.miss_am_time 
            that.Row.miss_pm_time = res.data.data.row.miss_pm_time 
            that.Row.advance_time = res.data.data.row.advance_time 
            that.Row.daily_time = res.data.data.row.daily_time 
            var krr = []
            for (let index = 0; index < res.data.data.sectionsList.length; index++) {
              krr[index] = {
                start : res.data.data.sectionsList[index].start,
                end : res.data.data.sectionsList[index].end
              }
            }
            that.SectionList = krr
            if(that.Row.late_time > 0){
              that.reissue_on = 1
            }else{
              that.reissue_on = 0
            }
            if(that.Row.miss_am_time > 0){
              that.reissue_on1 = 1
            }else{
              that.reissue_on1 = 0
            }
            if(that.Row.advance_time > 0){
              that.reissue_on2 = 1
            }else{
              that.reissue_on2 = 0
            }

        }
      })

    },
    //保存
    BtnEdit(){
      var that = this
      that.Row.sections = that.SectionList
      if(that.reissue_on2 == 0){
        that.Row.advance_time = ''
        that.Row.daily_time = ''
      }
      if(that.reissue_on1 === 0){
        that.Row.miss_am_time = ''
        that.Row.miss_pm_time = ''
      }
      if(that.reissue_on == 0){
        that.Row.late_time = ''
        that.Row.early_time = ''
      }
      axios({
        method: 'put',
        url: '/admin/attendance/attenclass/edit',
        data: {
          id: that.editId,
          row : that.Row
        },
      }).then(function (res) {
        console.log(res.data.data);
        if (res.data.code == 0) {
          that.$message.warning(res.data.msg);
        } else {
          that.$message.success(res.data.msg);
          that.editPanel = false
          that.loadTable();
        }
      })
    },
    //删除
    HandlDel(data){
      var that = this
      var arr = []
      arr.push(data.id)
      that.$confirm("确定要删除吗？", "提示", {
          type: "warning"
      }).then(() => {
          axios.delete('/admin/attendance/attenclass/del',{ 
            params:{
              ids: arr
            }
          }).then(function (res) {
          if (res.data.code == 0) {
              that.$message.warning(res.data.msg);
          } else{ 
              that.$message.success(res.data.msg); 
              that.loadTable()
            }
        })   
      })
    },
    TextMoMo(e) {
      var arr = TextMoth.getData(e)
      return arr  
    },
    // 搜索
    BtnSearch(e) {
      var that = this
      that.page.currentPage = 1;
      if(e == false){
        that.search.keyword = ''
      }
      that.loadTable()
    },
    Radio(e) {
      var that = this
      that.SectionList = []
      var arr = that.SectionList
      var acc = {start:'',end:''}
      if (e == 1) {
        arr.push(acc)
      } else if (e == 2) {
        arr.push(acc)
        arr.push(acc)
      } else if (e == 3) {
        arr.push(acc)
        arr.push(acc)
        arr.push(acc)
      }
      that.SectionList = arr
    },
    // 关闭
    Close() {
      var that = this
      that.editPanel = false;
      that.addPanel = false; 
      that.Row = {}
      that.SectionList = {}
    },
  }
}
</script>
<style scoped>
.EditNew{
  padding-left: 30px;
}
.EditNewFormBtns{
  margin-bottom: 20px;
}
.EditNewTop{
  padding: 0;
  padding-right: 24px;
}
.EditNewFormItemName{
  margin-bottom: 10px;
}
.check{
  margin-bottom: 8px;
}
</style>