

<template>
  <div class="AttendanceGroup_I">
    <div class="headNavs">
      <div>
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect">
          <el-menu-item index="1">考勤组设置</el-menu-item>
          <el-menu-item index="2">班次设置</el-menu-item>
          <el-menu-item index="3">假期设置</el-menu-item>
        </el-menu>
      </div>
      <div class="headDetails">说明：设置考勤部门与人员、考勤班次、考勤方式（Wi-Fi）等相关规则，点击下方添加考勤进入按步骤设置即可。</div>
    </div>
    <Holiday v-if="isShow == 3"></Holiday> <!-- 假期设置 -->
    <ShiftSetting v-if="isShow == 2"></ShiftSetting> <!-- 班次设置 -->
    <div v-if="isShow == 1">
      <div class="TablesList">
        <div class="TablesADD">
          <div class="NavLiReset" @click="BtnSearch(false)"><i class="el-icon-refresh"></i>刷新</div>
          <div class="NavLiQuery" @click="showAdd()" v-if="TextMoMo('attendance:group:add')"><i class="el-icon-plus"></i>添加考勤组</div>
          <div class="TableIpt"> 
            <input @keyup.enter.native="BtnSearch()" type="text" v-model="search.keyword" placeholder="请输入考勤组名称">
            <div class="seso" @click="BtnSearch()"><i class="el-icon-search"></i></div>
          </div>
        </div>
        <el-table
          :data="tableData"
          class="table"
          ref="multipleTable"
          header-cell-class-name="table-header">
          <el-table-column prop="id" label="ID" width="65" align="center"></el-table-column>
          <el-table-column align="center" prop="name" label="考勤组名称"></el-table-column>
          <el-table-column align="center" prop="leader.realname" label="考勤负责人"></el-table-column>
          <el-table-column align="center" prop="type_text" label="班次"></el-table-column>
          <el-table-column align="center" prop="createtime" label="考勤时间"></el-table-column>
          <el-table-column align="center" prop="person_num" label="考勤人数"></el-table-column>
          <el-table-column align="center" prop="mode" label="考勤方式"></el-table-column>
          <el-table-column label="操作" align="center" width="200">
            <template #default="scope">
                <div class="el_butto" >
                  <el-button
                  type="text"
                  @click="HandlEdit(scope.row)"
                  v-if="TextMoMo('attendance:group:edit')"
                  >编辑</el-button>
                </div>
              <div class="el_butto rere" >
                  <el-button
                  type="text"
                  class="red"
                  v-if="TextMoMo('attendance:group:del')"
                  @click="HandleDel(scope.row)"
                  >删除</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="block">
          <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="page.currentPage"
            :page-size="page.pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total">
          </el-pagination>
        </div>
      </div>
      <div class="show" v-if="addPanel || editPanel">
        <div class="rightPanel-background"/>
        <div class="EditNew">
          <div class="EditNewTop">
            <div>{{titleMath}}</div>
            <div @click="Close()"><i class="el-icon-close"></i></div>
          </div>

          <div class="EditNewForm">
            <div class="EditNewFormItem">
              <div class="EditNewFormItemName">考勤组名称：</div>
              <div class="EditNewFormItemIpt">
                <el-input v-model="Row.name" placeholder="请输入考勤组名称，比如：行政部门考勤"></el-input>
              </div>
            </div>
            <div class="EditNewFormItem">
              <div class="EditNewFormItemName">考勤负责人：</div>
              <div class="EditNewFormItemIpt">
                <el-select v-model="Row.leader_id" placeholder="请选择考勤负责人">
                  <el-option v-for="item in StaFFList" :key="item.id" :label="item.realname" :value="item.id"></el-option>
                </el-select>
              </div>
            </div>
            <div class="EditNewFormItem">
              <div class="EditNewFormItemName">考勤人员与部门：</div>
              <div class="EditNewFormItemIpt">
                <el-select v-model="Row.staff_ids" multiple placeholder="请选择考勤负责人">
                  <el-option v-for="item in StaFFList" :key="item.id" :label="item.realname" :value="item.id"></el-option>
                </el-select>
              </div>
            </div>
            <div class="EditNewFormItem">
              <div class="EditNewFormItemName">白名单：</div>
              <div class="EditNewFormItemIpt">
                <el-select v-model="Row.white_ids" placeholder="请选择白名单">
                  <el-option v-for="item in StaFFList" :key="item.id" :label="item.realname" :value="item.id"></el-option>
                </el-select>
              </div>
            </div>
            <div class="EditNewFormBtns">
              <div class="EditNewFormItemName">考勤类型：</div>
              <div class="FormItems">
                <el-radio v-model="Row.type" label="fixed">固定班制（每天考勤时间一样）</el-radio>
                <el-radio v-model="Row.type" label="class">排班制（自定义设置考勤时间）</el-radio>
                <el-radio v-model="Row.type" label="free">自由工时（不设置班次，随时打卡）</el-radio>
              </div>
            </div>
            <div class="EditNewFormBtns NewFormBtn">
              <div class="EditNewFormItemName">工作日设置：</div>
              <div class="EditNewItems">
                <div>快捷设置班次</div>
                <div>A班：09:00-18:00 19:00-22:00 23:00-次日02:00</div>
                <div>换班次</div>
              </div>
            </div>
            <div class="EditNewFormBtns">
              <el-table ref="multipleTable" :data="tableData2" tooltip-effect="dark" :header-cell-style="{background:'#FAFAFA'}"
                @selection-change="handleSelectionChange" show-overflow-tooltip="false">
                <el-table-column type="selection" width="43" show-overflow-tooltip="false" > </el-table-column>
                <el-table-column align="center" prop="date" label="工作日" width="155"></el-table-column>
                <el-table-column align="center" prop="daka" label="班次时间段" width="370"></el-table-column>
                <el-table-column align="center" label="操作" width="140">
                  <template>
                    <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">更换班次</el-button>
                  </template>
                </el-table-column>
              </el-table> 
            </div>
            <div class="EditNewFormBtns">
              <div class="Holiday">
                <div>节假日安排：</div>
                <div><el-checkbox v-model="Row.holiday_on" :true-label="1" :false-label="0">中国大陆法定节假日自动排休</el-checkbox></div>
              </div>
            </div>
            <div class="EditNewFormBtns">
              <div class="Holiday">
                <div>考勤方式：</div>
                <div>
                  <el-checkbox v-model="Row.location_on" :true-label="1" :false-label="0">位置打卡</el-checkbox>
                  <el-checkbox v-model="Row.wifi_on" :true-label="1" :false-label="0">wifi打卡</el-checkbox>
                </div>
              </div>
            </div>
            <div class="EditNewFormBtns">
              <div class="ADDTO">
                <div @click="TJAdd(1)">添加位置</div>
                <div>可添加多个办公地点</div>
              </div>
              <el-table ref="multipleTable" :data="LocationList" tooltip-effect="dark" :header-cell-style="{background:'#FAFAFA'}"
                  @selection-change="handleSelectionChange2" show-overflow-tooltip="false">
                <el-table-column type="selection" width="55" />
                <el-table-column align="center" prop="address" label="考勤地址" ></el-table-column>
                <el-table-column align="center" prop="distance" label="有效范围 (米)" width="100"></el-table-column>
                <el-table-column align="center" label="操作" width="100">
                  <template #default="scope">
                    <span class="ressd" @click="handl_Del(1, scope.row)" >删除</span>
                  </template>
                </el-table-column>
              </el-table> 
            </div>
            <div class="EditNewFormBtns">
              <div class="ADDTO">
                <div @click="TJAdd(2)">添加Wi-Fi</div>
                <div>可添加多个办公Wi-Fi</div>
              </div>
              <el-table ref="multipleTable" :data="WifiList" @selection-change="handleSelectionChange1">
                <el-table-column type="selection" width="55" />
                <el-table-column align="center" prop="ssid" label="名称"></el-table-column>
                <el-table-column align="center" prop="bssid" label="MAC地址"></el-table-column>
                <el-table-column align="center" label="操作" width="200">
                  <template #default="scope">
                    <span class="ressd" @click="handl_Del(2,scope.row.id)" >删除</span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="EditNewFormBtns">
              <div class="repairCard">
                <div class="EditNewFormItemName">补卡申请：</div>
              </div>
              <div class="Holiday">
                <div><el-checkbox v-model="Row.reissue_on" :true-label="1" :false-label="0">允许补卡申请</el-checkbox></div>
                <div style="color:#8C8C8C;">员工异常打卡时可提交申请，审批通过后修正异常</div>
              </div>
            </div>
            <div class="RepairCard" v-if="Row.reissue_on == 1">
              <div class="EditNewFormItem">
                <div class="EditNewFormItemName">补卡次数：</div>
                <div class="EditNewFormItemIpt">
                  <el-input v-model="Row.reissue_num" placeholder="请选择每月最多允许补卡次数" type="number"></el-input>
                </div>
              </div>
              <div class="EditNewFormItem">
                <div class="EditNewFormItemName">补卡有效期（天）：</div>
                <div class="EditNewFormItemIpt">
                  <el-input v-model="Row.reissue_time" placeholder="请选择补卡有效期" type="number"></el-input>
                </div>
              </div>
            </div>
            <div class="EditNewFormBtns" style="margin-top: 20px;">
              <div class="EditNewFormItemName">更多设置</div>
              <div class="Holiday">
                <div style="display:flex">
                  <el-checkbox v-model="Row.outside_on" label="允许外勤打卡" :true-label='1' :false-label='0'></el-checkbox>
                  <el-checkbox v-model="Row.outside_photo_on" label="外勤打卡需拍照" :true-label="1" :false-label="0"></el-checkbox>
                  <el-checkbox v-model="Row.photo_on" label="开启拍照打卡" :true-label="1" :false-label="0"></el-checkbox>
                  <el-checkbox v-model="Row.photo_album_on" label="不允许使用相册照片" :true-label="1" :false-label="0"></el-checkbox>
                </div>
              </div>
            </div>
            <div class="EditNewFormBtns">
               <div class="FormBtns">
                <div v-if="addPanel == true" class="FormBtnsQuery" @click="BtnAdd()">创建</div>
                <div v-if="editPanel == true" class="FormBtnsQuery" @click="BtnEdit()">保存</div>
                <div class="FormBtnsClose" @click="Close()">取消</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 添加位置 -->
      <el-dialog :title="titleMath" v-model="editVisible" width="35%">
        <el-form ref="form" :model="form" label-width="100px">
          <el-form-item label="填写位置名称">
            <el-input v-model="Location.name"></el-input>
          </el-form-item>
          <el-form-item label="填写详细地址">
            <el-input v-model="Location.address"></el-input>
          </el-form-item>
          <el-form-item label="有效范围">
            <el-input v-model="Location.distance"></el-input>
          </el-form-item>
          <div id="container"></div>
        </el-form>
        <template #footer>
          <span class="dialog-footer" style="margin-bottom:20px">
            <el-button type="primary" v-if="isFor == 1" @click="positionAdd()">确 定</el-button>
          </span>
        </template>
      </el-dialog>
      <!-- 添加Wifi -->
      <el-dialog :title="titleMath" v-model="editVisible2" width="27%">
        <el-form ref="form" label-width="100px">
          <el-form-item label="WIFI名称">
            <el-input v-model="WIFI.ssid"></el-input>
          </el-form-item>
          <el-form-item label="MAC地址">
            <el-input type="number" v-model="WIFI.bssid"></el-input>
          </el-form-item>
        </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button type="primary" v-if="isFor == 3" @click="WiFiAdd()">确 定</el-button>
          </span>
        </template>
      </el-dialog>
    </div>
  </div>
</template> 

<script>
import ShiftSetting from './components/ShiftSetting.vue'
import Holiday from './components/Holiday.vue'
import axios from 'axios'
import  TextMoth  from "../../components/utils.vue";
import AMapLoader from '@amap/amap-jsapi-loader'
	window._AMapSecurityConfig = {
  		securityJsCode: '0e421469d1396f611c38c84171bede50'
	}
export default {
  name: "basetable",
  components:{
    ShiftSetting,
    Holiday,
  },
  data() {
    return {
      tableData:[],//总列表
      StaFFList:[],   //全部员工列表
      WifiList:[],   // Wifi列表
      LocationList:[],   //位置列表
      total: 0,
      editId:'',
      titleMath:'',
      activeIndex:1,
      isShow:'',
      addPanel: false,
      editPanel: false, 
      editVisible: false,
      editVisible2: false,
      isFor:'',
      // 分页
      page: {
        currentPage: 1,
        pageSize: 10,
      }, 
      //搜索参数
      search: {
        keyword: "",
      },
      Row:{
        name:'',
        staff_ids:[],
        location_ids: [],
        wifi_ids:[],
        white_ids: [],
        leader_id:'',
        type:'',
        holiday_on:'',
        location_on:'',
        wifi_on:'',
        outside_on:'',
        outside_photo_on:'',
        photo_on:'',
        photo_album_on:'',
        reissue_on:'',
        reissue_num:'',
        reissue_time:'',
      },
      Location:{
        name:'',
        address:'',
        latitude:'',
        longitude:'',
        distance:'100',
      },
      WIFI:{
        ssid:'',
        bssid:'',
      },
    };
  },
  mounted() {
    var that = this
    that.loadTable(); 
  },
  methods: {
    // 获取列表数据
    loadTable() {
      var that = this
      this.isShow = 1
      axios({
        method: 'post',
        url: '/admin/attendance/group/index',
        data: {
         page: that.page.currentPage,
         limit: that.page.pageSize,
         filter: that.search,
        },
      }).then(function (res) {
        that.tableData = res.data.data.rows
        that.total = res.data.data.total
        that.$forceUpdate()
      })
    }, 
    //全部员工列表
    quanbu(){
      var that = this
      axios.get('/admin/ajax/staffList',{                   // 全部员工列表
      }).then(function (res) {
        console.log(res.data.data);
          if (res.data.code == 0) {
            alert('请求失败');  
          } else{ 
            that.StaFFList = res.data.data
          }
      })
    } ,  
    //导航选择
    handleSelect(key){
      if(key == 1){
        this.isShow = 1
      }else if(key == 2){
        this.isShow = 2
      }else if(key == 3){
        this.isShow = 3
      }
    },
    // 创建打开
    showAdd() {
      var that = this
      that.addPanel = true;
      that.titleMath = '添加考勤组'
      that.Row = {
        name:'',
        staff_ids:[],
        location_ids: [],
        wifi_ids:[],
        white_ids: [],
        leader_id:'',
        type:'fixed',
        holiday_on:1,
        location_on:1,
        wifi_on:1,
        outside_on:1,
        outside_photo_on:0,
        photo_on:1,
        photo_album_on:1,
        reissue_on: 1,
        reissue_num:'',
        reissue_time:'',
      }; 
      that.quanbu()
    },
    //新增
    BtnAdd() {
      var that = this 
      var arr = []
      arr.push(that.Row.white_ids)
      axios({
        method: 'post',
        url: '/admin/attendance/group/add',
        data: {
          row : {
            name : that.Row.name,
            staff_ids : that.Row.staff_ids,
            location_ids : that.Row.location_ids,
            wifi_ids : that.Row.wifi_ids,
            white_ids : arr,
            leader_id : that.Row.leader_id,
            type : that.Row.type,
            holiday_on : that.Row.holiday_on,
            location_on : that.Row.location_on,
            wifi_on : that.Row.wifi_on,
            outside_on : that.Row.outside_on,
            outside_photo_on : that.Row.outside_photo_on,
            photo_on : that.Row.photo_on,
            photo_album_on : that.Row.photo_album_on,
            reissue_on : that.Row.reissue_on,
            reissue_num : that.Row.reissue_num,
            reissue_time : that.Row.reissue_time,
          }
        },
      }).then(function (res) {
        console.log(res.data);
        if (res.data.code == 0) {
          that.$message.warning(res.data.msg);
        } else {
          that.$message.success(res.data.msg);
          that.addPanel = false
          that.loadTable();
        }
      })
    },
    //编辑详情
    HandlEdit(data){
      var that = this
      that.editPanel = true;
      that.quanbu()
      that.titleMath = '编辑'
      that.editId = data.id
      axios.get('/admin/attendance/group/read', {   // 详情
        params: {
          id: data.id
        }
      }).then(function (res) {
        console.log(res.data.data);
        if (res.data.code == 0) {
          alert('请求失败');
        } else{
          that.Row.name = res.data.data.row.name
          that.Row.location_ids = res.data.data.row.location_ids.map(parseInt)
          that.Row.wifi_ids = res.data.data.row.wifi_ids.map(parseInt)
          that.Row.white_ids = parseInt(res.data.data.row.white_ids)
          var arr =  res.data.data.row.staff_ids
          var Ner_arr = []
          for (let i = 0; i < arr.length; i++) {
            Ner_arr[i]  =  parseInt(arr[i]);
          }
          that.Row.staff_ids = Ner_arr
          console.log(that.Row.staff_ids)
          that.Row.leader_id = res.data.data.row.leader_id
          that.Row.type = res.data.data.row.type
          that.Row.holiday_on = res.data.data.row.holiday_on
          that.Row.location_on = res.data.data.row.location_on
          that.Row.wifi_on = res.data.data.row.wifi_on
          that.Row.outside_on = res.data.data.row.outside_on
          that.Row.outside_photo_on = res.data.data.row.outside_photo_on
          that.Row.photo_on = res.data.data.row.photo_on
          that.Row.photo_album_on = res.data.data.row.photo_album_on
          that.Row.reissue_on = res.data.data.row.reissue_on
          that.Row.reissue_num = res.data.data.row.reissue_num
          that.Row.reissue_time = res.data.data.row.reissue_time

          that.WifiList = res.data.data.wifiList
          that.LocationList = res.data.data.locationList
        }

      })
    },
    //编辑
    BtnEdit(){
      var that = this
       var arr = []
      arr.push(that.Row.white_ids)
      
      var aee = that.WifiList
      var yii = []
      for (let i = 0; i < aee.length; i++) {
        const ele = aee[i];
        yii.push(parseInt(ele.id))
      }
      that.Row.wifi_ids = yii

      var aee2 = that.LocationList
      var yii2 = []
      for (let i = 0; i < aee2.length; i++) {
        const ele = aee2[i];
        yii2.push(parseInt(ele.id))
      }
      that.Row.location_ids = yii2
      console.log(that.Row.location_ids)
      axios({
        method: 'put',
        url: '/admin/attendance/group/edit',
        data: {
          id: that.editId,
          row : {
            name : that.Row.name,
            staff_ids : that.Row.staff_ids,
            location_ids : that.Row.location_ids,
            wifi_ids : that.Row.wifi_ids,
            white_ids : arr,
            leader_id : that.Row.leader_id,
            type : that.Row.type,
            holiday_on : that.Row.holiday_on,
            location_on : that.Row.location_on,
            wifi_on : that.Row.wifi_on,
            outside_on : that.Row.outside_on,
            outside_photo_on : that.Row.outside_photo_on,
            photo_on : that.Row.photo_on,
            photo_album_on : that.Row.photo_album_on,
            reissue_on : that.Row.reissue_on,
            reissue_num : that.Row.reissue_num,
            reissue_time : that.Row.reissue_time,
               location_ids: that.Row.location_ids,
          }
        },
      }).then(function (res) {
        console.log(res.data.data);
        if (res.data.code == 0) {
          that.$message.warning(res.data.msg);
        } else {
          that.$message.success(res.data.msg);
          that.editPanel = false
          that.loadTable();
        }
      })
    },
    //删除
    HandleDel(data) {
      var that = this
      var arr = []
      arr.push(data.id)
      that.$confirm("确定要删除吗？", "提示", {
        type: "primary"
      }).then(() => {
        axios({
        method: 'DELETE',
        url: '/admin/attendance/group/del',
        data: {
          ids: arr
        }
      }).then(function (res) {
        if (res.data.code == 0) {
          that.$message.warning(res.data.msg);
        } else {
          that.$message.success(res.data.msg);
          that.loadTable();
        }
      })
      })
    },
    // 搜索
    BtnSearch(e) {
      var that = this
      that.page.currentPage = 1;
      if(e == false){
        that.search.keyword = ''
      }
      that.loadTable()
    },
    //添加打开
    TJAdd(e){
      var that = this
      if(e == 1){
        that.titleMath = '添加位置'
        that.editVisible = true
        that.isFor = 1
        that.initMap();
      }else if(e == 2){
        that.titleMath = '添加Wifi'
        that.editVisible2 = true 
        that.isFor = 3
      }
    },
    //添加位置
    positionAdd(){
      var that = this
      var arr = that.LocationList
      axios({
        method: 'post',
        url: '/admin/attendance/location/add',
        data: {
          row: that.Location
        },
      }).then(function (res) {

        var ob1 = {
          id:res.data.data.id,
          group_id:0,
          title:that.Location.name,
          name:that.Location.name,
          address:that.Location.address,
          distance:that.Location.distance
        }
        that.editVisible = false
        that.LocationList.push(ob1)
        
      }) 
    },
    //添加Wifi
    WiFiAdd(){
      var that = this
      var arr = that.WifiList
      if(that.WIFI.ssid == '' && that.WIFI.bssid == ''){
        that.$message.warning('请填写WIFI名称/请填写MAC地址');
        return
      }
      axios({
          method: 'post',
          url: '/admin/attendance/wifi/add',
          data: {
            row: that.WIFI
          },
        }).then(function (res) {
          arr.push(res.data.data)
          that.WifiList = arr
          that.editVisible2 = false
        }) 

    },
    //删除弹出岑
    handl_Del(e,id){
      var that = this
      if (e == 1) {
        let index = that.LocationList.findIndex(obj => obj.index  === id)
        that.LocationList.splice(index, 1)
      } else if (e == 2) {
        let index = that.WifiList.findIndex(obj => obj.index  === id)
        that.WifiList.splice(index, 1)
      }
    },
    // 勾选wifi
    handleSelectionChange1(e) {          
      var that = this
      var arr = []
      if (e.length == 0) {
        that.multipleSelection_id1 = null
       } else {
        for (let i = 0; i < e.length; i++) {
          arr.push(parseInt(e[i].id))
        }
          that.Row.wifi_ids = arr
      }
    },
    // 勾选位置
    handleSelectionChange2(e) {          
      var that = this
      var arr = []
      if (e.length == 0) {
        that.multipleSelection_id1 = null
       } else {
        for (let i = 0; i < e.length; i++) {
          arr.push(parseInt(e[i].id))
        }
          that.Row.location_ids = arr
      }
    },
    TextMoMo(e) {
      var arr = TextMoth.getData(e)
      return arr  
    },
    //分页
    handleCurrentChange(page) {
      var that = this
      that.page.currentPage = page;
      that.loadTable();
    },
    // 关闭
    Close(e) {
      var that = this
      that.editPanel = false;
      that.addPanel = false; 
      that.Row = {}
    },

    initMap() {
      AMapLoader.load({
        key: "3d62b06249da3d2faabfb630eb3916b0", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ["AMap.Geocoder"], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      })
        .then((AMap) => {

          window.AMap = AMap;
          this.map = new AMap.Map("container", {//设置地图容器id
            viewMode: "3D", //是否为3D地图模式
            zoom: 11, //初始化地图级别
            center: [118.771409, 32.067939], //初始化地图中心点位置
          });
          // 设置点样式
          this.icon = new AMap.Icon({
            image: '//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png',  // Icon的图像
            offset: new AMap.Pixel(-13, -30)
          });
          let geocoder
          AMap.plugin('AMap.Geocoder',function(){
            geocoder = new AMap.Geocoder()
          })
          this.geocoder = geocoder
          var that= this
          that.map.on('click', function (e) {
            var lnglatXY = [e.lnglat.getLng(), e.lnglat.getLat()];
            that.openPop(lnglatXY)
          });
          // this.map.on('click',this.openPop); // 给点标记添加点击事件，写入对应函数
          // this.map.add(this.maker); // 添加点标记
          console.log(this.geocoder)
        })
        .catch((e) => {
          console.log(e);
        });
    },

    openPop(e) {
        var that = this
        that.markersPosition = e
        that.Location.latitude = e[0]
        that.Location.longitude = e[1]
        // 设置新的标记
        console.log(e)
        AMap.plugin('AMap.Geocoder', function () {
          var geocoder = new AMap.Geocoder({
            radius: 1000,
            extensions: "all"
          });
          console.log(geocoder)
          geocoder.getAddress(e, function (status, result) {
            if (status === 'complete' && result.info === 'OK') {
              var address = result.regeocode.formattedAddress;
              // that.address = address  //兑换地址
              console.log(result)
              that.Location.name = result.regeocode.pois[0].name  
              that.Location.address = result.regeocode.formattedAddress
              // that.$emit('getaddress', that.address, e)
            } else {
              // ElMessage.error('无地址，请重新选择');
              console.log(status)
            }
          });
          // 清除所有标记
          that.map.clearMap();
          var marker = new AMap.Marker();
          that.map.add(marker);
          marker.setPosition(e);
        })

    },
 
    addMarker () {
      //   经度 纬度
      let lng = Math.random() * 135.05 + 73.3
      let lat = Math.random() * 53.33 + 3.51
      console.log('添加标记', [lng, lat])
      // 添加标记
      this.map.setFitView()

      let marker = new AMap.Marker({
        map: this.map,
        position: [lng, lat]
        // content: markerContent
        // eslint-disable-next-line
                // offset: new AMap.Pixel(-13, -30)
      })
      // 将 markers 添加到地图
      this.markers.push(marker)

      this.map.setFitView()`在这里插入代码片`

      // 鼠标点击marker弹出自定义的信息窗体
      // eslint-disable-next-line no-undef
      AMap.event.addListener(marker, 'click', function (e) {
        console.log('点击marker', e)
      })
    },
  },
};
</script>

<style scoped>
.EditNew{
  width: 800px;
}
.EditNewFormItem{
  margin-bottom: 20px;
}
.EditNewFormItem .el-input{
  line-height: 32px;
}
.EditNewFormItemName{
  margin-bottom: 12px;
}
.EditNewItems div{
  margin-right: 20px;
}
.EditNewItems div:nth-child(3){
  color: #0081FF;
  cursor: pointer;
}
.EditNewFormBtns .el-table{
  margin-top: 20px;
  width: 94%;
}
.NewFormBtn{
    margin-top: 20px;
}
.EditNewItems{
    display: flex;
    font-weight: 500;
    color: #595959;
}
.Holiday{
    display: flex;
    margin-top: 20px;
}
.Holiday div:nth-child(1){
    width: 150px;
    text-align: left;
}
.ADDTO{
  display: flex;
  margin-top: 20px; 
  align-items: center;
  color: #8C8C8C;
}
.ADDTO div:nth-child(1){
  width: 80px;
  height: 32px;
  background: #FFFFFF;
  border: 1px solid #0081FF;
  border-radius: 3px;
  line-height: 32px;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  color: #0081FF;
  cursor: pointer;
  margin-right: 20px ;
}
.repairCard{
  margin-top: 20px;
  text-align: left;
}
.RepairCard{
  display: flex;
  width: 100%;
  margin-top: 20px;
}
#container {
  width: 100%;
  height: 400px;
  background: #d9d9d9;
}
</style>
