<template>
  <div class="Holiday_I">
    <div class="TablesList">
      <div class="TablesADD">
        <div class="NavLiReset" @click="BtnSearch(false)"><i class="el-icon-refresh"></i>刷新</div>
        <div class="NavLiQuery" @click="showAdd()" v-if="TextMoMo('attendance:group:add')"><i class="el-icon-plus"></i>添加假期</div>
        <div class="TableIpt"> 
          <input @keyup.enter.native="BtnSearch()" type="text" v-model="search.keyword" placeholder="请输入介绍">
          <div class="seso" @click="BtnSearch()"><i class="el-icon-search"></i></div>
        </div>
      </div>
      <el-table
        :data="tableData"
        class="table"
        ref="multipleTable"
        header-cell-class-name="table-header">
        <el-table-column prop="id" label="ID" width="70" align="center"></el-table-column>
        <el-table-column align="center" prop="intro" label="介绍"></el-table-column>
        <el-table-column align="center" prop="type_text" label="类型"></el-table-column>
        <el-table-column align="center" prop="starttime_text" label="开始时间"></el-table-column>
        <el-table-column align="center" prop="endtime_text" label="结束时间"></el-table-column>
        <el-table-column label="操作" align="center" width="200">
          <template #default="scope">
              <div class="el_butto" >
                <el-button
                type="text"
                @click="HandlEdit(scope.row)" v-if="TextMoMo('attendance:group:edit')"
                >编辑</el-button>
              </div>
            <div class="el_butto rere" >
                <el-button
                type="text"
                v-if="TextMoMo('attendance:group:del')"
                class="red"
                @click="HandlDel(scope.row)"
                >删除</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          background
          @current-change="handleCurrentChange"
          :current-page="page.currentPage"
          :page-size="page.pageSize"
          layout="total, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
    </div>

    <el-dialog :title="titleMath" v-model="editVisible" width="27%">
      <el-form :model="Row" :rules="rules" ref="Row" label-width="80px">
        <el-form-item label="介绍" prop="intro">
          <el-input v-model="Row.intro" placeholder="请输入介绍内容"></el-input>
        </el-form-item>
        <el-form-item label="类型" prop="type">
          <el-select v-model="Row.type" placeholder="请选择类型">
              <el-option v-for="item in Opidss" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="开始时间" prop="starttime" >
          <el-date-picker v-model="Row.starttime" type="date" placeholder="选择开始时间" :clearable="false"></el-date-picker>
        </el-form-item>
        <el-form-item label="结束时间" prop="endtime">
          <el-date-picker v-model="Row.endtime" type="date" placeholder="选择结束时间" :clearable="false"></el-date-picker>
        </el-form-item>        
      </el-form>
      <template #footer>
        <span class="dialog-footer" style="margin-bottom:20px">
          <el-button @click="Close()">取 消</el-button>
          <el-button type="primary" @click="BtnAdd()"  v-if="isShow == 1">确 定</el-button>
          <el-button type="primary" @click="BtnEdit()" v-if="isShow == 2">确 定</el-button>
        </span>
      </template>
    </el-dialog>
 


  </div>
</template>

<script>
import axios from 'axios'
import TextMoth  from "../../../components/utils.vue";
export default {
  data(){
    return{
      tableData:[],
      SectionList:[], //班次时间
      StaFFList:[],
      total:'',
      titleMath:'',
      editId:'',
      isShow:'',
      editVisible:false,
      Opidss:[{name:'上班',id:'work'},{name:'休息',id:'rest'}],
      // 分页
      page: {
        currentPage: 1,
        pageSize: 10,
      }, 
      //搜索参数
      search: {
        keyword: "",
      },
      Row:{
        starttime:'',
        endtime:'',
        type: '',
        intro:'',
      },
      rules:{
        starttime: [{ required: true, message: '选择开始时间', trigger: 'change' }],
        endtime: [{ required: true, message: '选择结束时间', trigger: 'change' }],
        type: [{ required: true, message: '请选择类型', trigger: 'change' }],
        intro: [{ required: true, message: '请输入介绍内容', trigger: 'blur' }],
      }
    }
  },
  mounted(){
    var that = this
    that.loadTable(); 
  },
  methods:{
    // 获取列表数据
    loadTable() {
      var that = this
      axios({
        method: 'post',
        url: '/admin/attendance/calendar/index',
        data: {
         page: that.page.currentPage,
         limit: that.page.pageSize,
         filter: that.search,
        },
      }).then(function (res) {
        that.tableData = res.data.data.rows
        that.total = res.data.data.total
        that.$forceUpdate()
      })
    }, 
    //全部员工列表
    quanbu(){
      var that = this
      axios.get('/admin/ajax/staffList',{                   // 全部员工列表
      }).then(function (res) {
        if (res.data.code == 0) {
          alert('请求失败');  
        } else{ 
          that.StaFFList = res.data.data
        }
      })
    } , 
    // 创建打开
    showAdd() {
      var that = this
      that.editVisible = true;
      that.isShow = 1
      that.titleMath = '创建假期'
      that.Row = {
        starttime:'',
        endtime:'',
        type: '',
        intro:'',
      }; 
    },
    //新增
    BtnAdd(){
      var that = this 
      that.$refs['Row'].validate((valid) =>{
        if (valid) {
          axios({
            method: 'post',
            url: '/admin/attendance/calendar/add',
            data: {
              row : that.Row
            },
          }).then(function (res) {
            console.log(res.data);
            if (res.data.code == 0) {
              that.$message.warning(res.data.msg);
            } else {
              that.$message.success(res.data.msg);
              that.editVisible = false
              that.loadTable();
            }
          })
        }
      })
    },
    //编辑
    HandlEdit(data){
      var that = this
      that.editVisible = true
      that.isShow = 2
      that.editId = data.id
      that.titleMath = '编辑假期'
      that.Row.intro = data.intro
      that.Row.starttime = data.starttime_text
      that.Row.endtime = data.endtime_text
      that.Row.type = data.type
    },
    //保存
    BtnEdit(){
      var that = this
      that.$refs['Row'].validate((valid) =>{
        if (valid) {
          axios({
            method: 'put',
            url: '/admin/attendance/calendar/edit',
            data: {
              id: that.editId,
              row : that.Row
            },
          }).then(function (res) {
            console.log(res.data.data);
            if (res.data.code == 0) {
              that.$message.warning(res.data.msg);
            } else {
              that.$message.success(res.data.msg);
              that.editVisible = false
              that.loadTable();
            }
          })
        }
      }) 
    },
    //删除
    HandlDel(data){
      var that = this
      var arr = []
      arr.push(data.id)
      that.$confirm("确定要删除吗？", "提示", {
          type: "warning"
      }).then(() => {
          axios.delete('/admin/attendance/calendar/del',{ 
            params:{
              ids: arr
            }
          }).then(function (res) {
          if (res.data.code == 0) {
              that.$message.warning(res.data.msg);
          } else{ 
              that.$message.success(res.data.msg); 
              that.loadTable()
            }
        })   
      })
    },
    TextMoMo(e) {
      var arr = TextMoth.getData(e)
      return arr  
    },
    // 搜索
    BtnSearch(e) {
      var that = this
      that.page.currentPage = 1;
      if(e == false){
        that.search.keyword = ''
      }
      that.loadTable()
    },
    Radio(e) {
      var that = this
      that.SectionList = []
      var arr = that.SectionList
      var acc = {start:'',end:''}
      if (e == 1) {
        arr.push(acc)
      } else if (e == 2) {
        arr.push(acc)
        arr.push(acc)
      } else if (e == 3) {
        arr.push(acc)
        arr.push(acc)
        arr.push(acc)
      }
      that.SectionList = arr
      // console.log(that.Row.sections);
    },
    // 关闭
    Close() {
      var that = this
      that.editVisible = false;
      that.Row = {}
    },
  }
}
</script>

